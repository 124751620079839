import * as React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Icons from "../components/icons/services"
import Project from "../templates/projects/project"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowDown } from "@fortawesome/pro-regular-svg-icons"

import { faBookSpells } from "@fortawesome/pro-duotone-svg-icons"

const IndexPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { projects },
    },
  } = data

  const recentProjects = projects.filter(i => i.recent)

  return (
    <Layout>
      <Seo title="Home" />
      <section className="pt-10 lg:pb-32 md:pb-32 sm:pt-16 lg:pt-14 lg:overflow-hidden bg-none lg:relative bg-blue-100">
        <div className="container">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="max-w-md sm:max-w-2xl text-left lg:flex">
              <div className="lg:py-12 lg:mb-32 lg:max-w-md">
                <h1 className="lg:text-5xl lg:leading-tight">
                  Lowering the barriers <br />
                  <span className="font-medium">to data insights</span>
                </h1>
                <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
                <h4 className="mt-12 lg:mt-20 mb-8 lg:mb-12 lg:max-w-sm text-gray-700">
                  Keshif means{" "}
                  <span className="font-medium">data made explorable</span>.
                  <br />
                  Our flagship technology enables 360-degree exploratory
                  analysis with ease and speed. Our solutions bridge the gap
                  between data and action for mission-driven organizations.
                </h4>
                <a href="#Technology" className="btn-secondary">
                  <FontAwesomeIcon className="mr-3" icon={faArrowDown} />
                  Explore our work
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-7/12 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover lg:object-left lg:pl-24 lg:pt-0 px-4 mt-12"
            src="https://storage.googleapis.com/keshif-website/Frontpage/main-hero.png"
            alt=""
          />
        </div>
      </section>
      <div id="Technology"></div>
      {/*
      <section className="container py-12 sm:py-20 lg:py-20">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-x-28 lg:gap-y-14 gap-8">
          <div className="border-l pl-5 border-gray-300">
            <h5 className="font-medium mb-2">
              Interactive, 360-degree control
            </h5>
            <p>
              See beyond static charts and snapshot results. Inspiring
              engagement and in-depth 360 exploration. Find and answer new
              questions.{" "}
            </p>
          </div>
          <div className="border-l pl-5 border-gray-300">
            <h5 className="font-medium mb-2">Automated with best practices</h5>
            <p>
              Chart based on data and analytics goals. Perceptual accuracy.
              Communicate data.
            </p>
          </div>
          <div className="border-l pl-5 border-gray-300">
            <h5 className="font-medium mb-2">Rapid data exploration</h5>
            <p>
              Analytics at the speed of thought, with fully synchronized,
              dynamically updated charts and dashboards at every step in real
              time.
            </p>
          </div>
          <div className="border-l pl-5 border-gray-300">
            <h5 className="font-medium mb-2">Easy to learn</h5>
            <p>
              We offer core analytics features in our distilled data analytics
              design.
            </p>
          </div>
          <div className="border-l pl-5 border-gray-300">
            <h5 className="font-medium mb-2">Innovative</h5>
            <p>
              Based on research. Early work published in leading academic
              journals on visual data analytics and human computer interaction.
            </p>
          </div>
          <div className="border-l pl-5 border-gray-300">
            <h5 className="font-medium mb-2">Web-based</h5>
            <p>No other application required except a modern web browser.</p>
          </div>
        </div>
      </section>
*/}
      <section className="pt-12 sm:pt-20 lg:pt-44 bg-blueGradient relative">
        <div className="container grid lg:grid-cols-2">
          <div className="max-w-lg">
            <h3 className="font-light">
              Learn how to <b>visualize and explore data in an instant </b>
              by creating and controlling rich dashboards and interactive charts
              with Keshif.
            </h3>
            <a
              href="https://help.keshif.me/"
              target="_blank"
              className="btn-primary mt-7"
            >
              <FontAwesomeIcon className="mr-3" icon={faBookSpells} />
              Visit our knowledge base
            </a>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full lg:mt-20">
          <img
            className="absolute inset-0 w-full h-full object-contain px-4"
            src="https://storage.googleapis.com/keshif-website/Frontpage/Knowldege-base.png"
            alt=""
          />
        </div>
      </section>
      <section className="container lg:relative sm:pt-24 lg:pt-36">
        <img
          draggable="false"
          className="lg:block md:block hidden absolute left-0 -top-20 pointer-events-none -z-1"
          alt="Section Pattern"
          src="https://storage.googleapis.com/keshif-website/Illustrations/Pattern.svg"
        />
        <div className="max-w-lg lg:mb-20 md:mb-8 mb-4">
          <h2>Our Solutions</h2>
          <h4 className="lg:mt-10 md:mt-8 mt-4">
            Our technology and services adapt across different datasets of many
            types and sizes. We convert rows and columns of tables and databases
            into rich, interactive, explorative charts and dashboards. Learn
            more about our key offerings for datasets we commonly work with in
            our solutions pages.
          </h4>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5">
          <Link
            to="/Solutions/Surveys"
            className="grid-element disable-underline"
          >
            <div className="flex lg:flex-row md:flex-row flex-col gap-5 lg:py-7 md:py-7 lg:px-9 md:px-9 px-5 py-5 bg-gray-50 rounded-sm hover:shadow-lg transition-all">
              <img
                alt="Surveys"
                className="w-16 object-contain lg:w-36"
                src="https://storage.googleapis.com/keshif-website/Frontpage/Surveys_small.png"
              ></img>
              <div>
                <div className="space-y-2 flex-1 mb-2">
                  <h3 className="font-medium">Surveys</h3>
                  <h4 className="lg:text-base text-sm">
                    Your interactive, 360-degree dashboards into analysing what
                    people think.
                  </h4>
                </div>
                <div className="text-sm text-purple-600 font-medium mt-5">
                  <FontAwesomeIcon className="mr-3" icon={faArrowRight} />
                  Learn more
                </div>
              </div>
            </div>
          </Link>
          <Link
            to="/Solutions/Incidents"
            className="grid-element disable-underline"
          >
            <div className="flex lg:flex-row md:flex-row flex-col gap-5 lg:py-7 md:py-7 lg:px-9 md:px-9 px-5 py-5 bg-gray-50 rounded-sm  hover:shadow-lg transition-all">
              <img
                className="w-16 object-contain lg:w-36"
                alt="Incidents"
                src="https://storage.googleapis.com/keshif-website/Frontpage/Incidents_small.png"
              ></img>
              <div>
                <div className="space-y-2 flex-1 mb-2">
                  <h3 className="font-medium">Incidents</h3>
                  <h4 className="lg:text-base text-sm">
                    Discover the why by visually exploring who, what, when,
                    where & how.
                  </h4>
                </div>
                <div className="text-sm text-purple-600 font-medium mt-5">
                  <FontAwesomeIcon className="mr-3" icon={faArrowRight} />
                  Learn more
                </div>
              </div>
            </div>
          </Link>
          <Link
            to="/Solutions/Elections"
            className="grid-element disable-underline"
          >
            <div className="flex lg:flex-row md:flex-row flex-col gap-5 lg:py-7 md:py-7 lg:px-9 md:px-9 px-5 py-5 bg-gray-50 rounded-sm grid-element hover:shadow-lg transition-all">
              <img
                className="w-16 object-contain lg:w-36"
                alt="Elections"
                src="https://storage.googleapis.com/keshif-website/Frontpage/Elections_small.png"
              ></img>
              <div>
                <div className="space-y-2 flex-1 mb-2">
                  <h3 className="font-medium">Election Results</h3>
                  <h4 className="lg:text-base text-sm">
                    Elections are the voice of the people. Our solutions bring
                    insight to their voice.
                  </h4>
                </div>
                <div className="text-sm text-purple-600 font-medium mt-5">
                  <FontAwesomeIcon className="mr-3" icon={faArrowRight} />
                  Learn more
                </div>
              </div>
            </div>
          </Link>
          <Link
            to="/Solutions/Indicators"
            className="grid-element disable-underline"
          >
            <div className="flex lg:flex-row md:flex-row flex-col gap-5 lg:py-7 md:py-7 lg:px-9 md:px-9 px-5 py-5 bg-gray-50 rounded-sm hover:shadow-lg transition-all">
              <img
                className="w-16 object-contain lg:w-36"
                alt="Indicators"
                src="https://storage.googleapis.com/keshif-website/Frontpage/Indicators_small.png"
              ></img>
              <div>
                <div className="space-y-2 flex-1 mb-2">
                  <h3 className="font-medium">Indicators</h3>
                  <h4 className="lg:text-base text-sm">
                    Explore how data evolves over time, locations, and
                    dimensions
                  </h4>
                </div>
                <div className="text-sm text-purple-600 font-medium mt-5">
                  <FontAwesomeIcon className="mr-3" icon={faArrowRight} />
                  Learn more
                </div>
              </div>
            </div>
          </Link>
          <div className="flex lg:invisible md:invisible"></div>
          <div className="flex lg:flex-row md:flex-row flex-col gap-5 lg:py-7 md:py-7 lg:px-9 md:px-9 px-5 py-5 bg-gray-50 rounded-sm grid-element hover:shadow-lg cursor-default transition-all">
            <img
              alt="Knowledge"
              className="w-16 object-contain lg:w-36"
              src="https://storage.googleapis.com/keshif-website/Frontpage/Knowledge_small.png"
            ></img>
            <div>
              <div className="space-y-2 flex-1 mb-2">
                <h3 className="font-medium">Knowledge Management</h3>
                <h4 className="lg:text-base text-sm">
                  Collect, organize, and explore data. Transform information to
                  actionable insights.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blueGradientInverted py-12 sm:py-20 lg:py-20">
        <div className="container">
          <h2 className="mb-4 lg:mb-6">Services</h2>
          <div className="grid lg:grid-cols-2 gap-8">
            <div className="space-y-7 max-w-md">
              <h4 className="lg:text-base text-sm">
                We solve important data analytics challenges with full-scope,
                result-oriented application development and advisory services
                powered by Keshif’s innovative and rapid visual data exploration
                technology.{" "}
              </h4>
              <Link to="/Services" className="btn-primary">
                <FontAwesomeIcon className="mr-3" icon={faArrowRight} />
                Learn more
              </Link>
            </div>
            <ul className="grid lg:grid-cols-2 md:grid-cols-2 gap-4">
              <li className="flex items-center bg-white rounded-full cursor-default hover:shadow-md transition-all">
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                  <Icons
                    className="p-1.5 text-purple-600"
                    size="2x"
                    icon="Visual Data Analytics and Dashboarding"
                  />
                </div>
                <span className="pl-4 flex-1 text-sm">
                  Visual Data Analytics and Dashboarding
                </span>
              </li>
              <li className="flex items-center bg-white rounded-full cursor-default hover:shadow-md transition-all">
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                  <Icons
                    className="p-1.5 text-purple-600"
                    size="2x"
                    icon="Data Preparation"
                  />
                </div>
                <span className="pl-4 flex-1 text-sm">Data Preparation</span>
              </li>
              <li className="flex items-center bg-white rounded-full cursor-default hover:shadow-md transition-all">
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                  <Icons
                    className="p-1.5 text-purple-600"
                    size="2x"
                    icon="Data Collection"
                  />
                </div>
                <span className="pl-4 flex-1 text-sm">Data Collection</span>
              </li>
              <li className="flex items-center bg-white rounded-full cursor-default hover:shadow-md transition-all">
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                  <Icons
                    className="text-purple-600"
                    size="2x"
                    icon="Data Strategy"
                  />
                </div>
                <span className="pl-4 flex-1 text-sm">Data Strategy</span>
              </li>
              <li className="flex items-center bg-white rounded-full cursor-default hover:shadow-md transition-all">
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                  <Icons
                    className="p-1.5 text-purple-600"
                    size="2x"
                    icon="Services Beyond"
                  />
                </div>
                <span className="pl-4 flex-1 text-sm">Services Beyond</span>
              </li>
              <li className="flex items-center bg-white rounded-full cursor-default hover:shadow-md transition-all">
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                  <Icons
                    className="p-1.5 text-purple-600"
                    size="2x"
                    icon="Web Design and Development"
                  />
                </div>
                <span className="pl-4 flex-1 text-sm">
                  Web Design and Development
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="container mt-12">
          <h3 className="mb-4 lg:mb-6">Sectors</h3>
          <div className="grid lg:grid-cols-2 gap-4">
            <h4 className="md:max-w-md lg:max-w-md lg:pr-11 md:pr-11">
              We enjoy working with mission-driven organizations across sectors,
              and contributing with our technology and expertise to create data
              solutions that improve the wellbeing of people around the world.
            </h4>
            <div className="flex gap-3 flex-wrap content-start">
              <span className="bg-white rounded-md text-purple-600 font-medium px-4 py-3 inline-flex items-center text-sm hover:shadow-lg cursor-default transition-all">
                Monitoring, Evaluation &amp; Learning
              </span>
              <span className="bg-white rounded-md text-purple-600 font-medium px-4 py-3 inline-flex items-center text-sm hover:shadow-lg cursor-default transition-all">
                Economy, Finance &amp; Trade
              </span>
              <span className="bg-white rounded-md text-purple-600 font-medium px-4 py-3 inline-flex items-center text-sm hover:shadow-lg cursor-default transition-all">
                Health
              </span>
              <span className="bg-white rounded-md text-purple-600 font-medium px-4 py-3 inline-flex items-center text-sm hover:shadow-lg cursor-default transition-all">
                Democracy &amp; Governance
              </span>
              <span className="bg-white rounded-md text-purple-600 font-medium px-4 py-3 inline-flex items-center text-sm hover:shadow-lg cursor-default transition-all">
                Stabilization &amp; Transition
              </span>
              <span className="bg-white rounded-md text-purple-600 font-medium px-4 py-3 inline-flex items-center text-sm hover:shadow-lg cursor-default transition-all">
                Defence
              </span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <h2 className="mb-8 mt-12">Recent Projects</h2>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5 items-stretch">
            {recentProjects.map(i => (
              <Project key={i.slug} project={i} />
            ))}
            <Link
              to="/Projects"
              style={{
                backgroundImage:
                  "url('https://storage.googleapis.com/keshif-website/Frontpage/Projects.png')",
                backgroundSize: "cover",
              }}
              className="block rounded border-gray-100 bg-gray-50 group hover:-translate-y-1 hover:shadow-xl group transform transition-all h-full grid place-content-center disable-underline"
            >
              <div className="lg:px-7 px-4 py-5 sm:p-4">
                <h2>
                  <b>
                    20
                    <sup>
                      <small>+</small>
                    </sup>{" "}
                    projects
                  </b>
                  <br />
                  with local and global impact
                </h2>
                <div className="btn-primary mt-10 flex items-center disable-underline">
                  <FontAwesomeIcon className="mr-3" icon={faArrowRight} />
                  All Projects
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 sm:py-20 lg:py-20">
        <div className="container grid lg:grid-cols-2 gap-8 items-center">
          <img
            alt="Clients"
            src="https://storage.googleapis.com/keshif-website/Frontpage/Clients-1.png"
          ></img>
          <img
            alt="Clients"
            src="https://storage.googleapis.com/keshif-website/Frontpage/Clients-2.png"
          ></img>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Products {
    markdownRemark(frontmatter: { slug: { eq: "/Projects" } }) {
      frontmatter {
        projects {
          slug
          title
          recent
          solutions
          client {
            name
          }
        }
      }
    }
  }
`

export default IndexPage
